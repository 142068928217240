<template>
  <div>
    <van-button type="info" round size="large" @click="sendHMessage"
      >发送</van-button
    >
  </div>
</template>
<script>
export default {
  data() {
    return {
      title: "", //头部标题
      customerId: "", //客服id
    };
  },
  methods: {
    sendHMessage() {
      window.wx.invoke(
        "sendChatMessage",
        {
          msgtype: "text", //消息类型，必填
          enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
          text: {
            content: "<a href='https://tool.farbun.com'>点击</a>",
          },
        },
        (res) => {
          console.log("服务指引返回结果", res);
          if (res.err_msg == "sendChatMessage:ok") {
            //记录日志
            this.$toast("内容已发送到聊天窗口");
          }
        }
      );
    },
    //查询客服信息
    initCustomer(val) {
      this.$axios
        .get(`${this.$base}/fission/page/queryOneCustomer?userId=${val}`)
        .then((res) => {
          if (res.code  === "200") {
            this.customerId = res.data.id;
          }
        });
    },
    //初始化微信ticket
    initWxTicket() {
      this.$axios
        .get(
          `${this.$base}/fission/page/getTicket?agentId=1000031&type=4&url=${
            location.href.split("#")[0]
          }`
        )
        .then((res) => {
          if (res.code === "200") {
            this.appId = res.data.pId;
            this.agentid = res.data.agentId;
            this.timestamp = res.data.timeStamp;
            this.nonceStr = res.data.nonceStr;
            this.signature = res.data.signature;
            this.agentSignature = res.data.agentSignature;
            this.config();
          }
        });
    },
    config() {
      window.wx.config({
        beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: this.appId, // 必填，企业微信的corpID
        agentid: this.agentid, //第三方应用id
        timestamp: this.timestamp, // 必填，生成签名的时间戳
        nonceStr: this.nonceStr, // 必填，生成签名的随机串
        signature: this.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
        jsApiList: ["agentConfig"], // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
      });

      window.wx.ready(function () {
        // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
        window.wx.checkJsApi({
          jsApiList: ["agentConfig"], //需要检测的js接口
          success: function () {},
          fail: function () {
            console.log("--------接口校验失败---------");
          },
        });
      });

      window.wx.error(function (res) {
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
        console.log("-----sdk校验失败:" + res.errMsg);
      });

      /**
       * 获取外部联系人id
       */
      window.wx.agentConfig({
        corpid: this.appId, // 必填，企业微信的corpID
        agentid: this.agentid,
        timestamp: this.timestamp, // 必填，生成签名的时间戳
        nonceStr: this.nonceStr, // 必填，生成签名的随机串
        signature: this.agentSignature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
        jsApiList: ["getCurExternalContact", "getContext", "sendChatMessage"], // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
        success: this.onSuccess,
        fail: function () {
          // if (res.err_msg.indexOf("function not exist") > -1) {
          //   this.jsApiList.performAlert("版本过低请升级");
          // }
        },
      });
    },
    onSuccess() {
      window.wx.invoke("getContext", {}, (res) => {
        if (res.err_msg == "getContext:ok") {
          // console.log("-------进入页面的类型:" + res); //返回进入H5页面的入口类型，目前有normal、contact_profile、single_chat_tools、group_chat_tools、chat_attachment
          //可用于调用getShareInfo接口
          window.wx.invoke("getCurExternalContact", {}, (res) => {
            console.log("-------进入页面的类型:", res);
            if (res.err_msg == "getCurExternalContact:ok") {
              this.userInfo = res.userId;
              this.initUser();
            } else {
              //错误处理
              console.log("-----------无法获取外部用户:" + res.err_msg);
            }
          });
        } else {
          //错误处理
        }
      });
    },
    //初始化头部信息
    initUser() {
      this.$axios
        .get(`${this.$base}/fission/page/getByUserId?userId=${this.userInfo}`)
        .then((res) => {
          if (res.errcode === 0) {
            this.userUnionId = res.external_contact.unionid;
            this.title = res.external_contact.name;
          }
        });
    },
  },
  mounted() {
    console.log("客服id", this.customerId);
  },
  created() {
    this.initCustomer(this.$route.query.id);
    this.initWxTicket();
  },
};
</script>